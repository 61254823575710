import React from 'react';
import Image from '@/components/elements/Image';
import Video from '@/components/elements/Video';
import ModuleWrapper from '@/components/modules/ModuleWrapper/ModuleWrapper';
import SectionRichText from '../SectionRichText';
import type { HeroTypes } from './Hero.types';
import * as styles from './Hero.module.scss';

export default function Hero({
  eyebrow,
  body,
  image,
  image_position,
  video,
  className,
  widestContent = false,
  top_spacing = 'md',
  bottom_spacing = 'md',
}: HeroTypes) {
  const castedSide = String(image_position);
  const castedWidestContente = String(widestContent);

  return (
    <ModuleWrapper topSpacing={top_spacing} bottomSpacing={bottom_spacing} tag="section">
      <div className={styles.wrapper} data-side={castedSide} data-widest={castedWidestContente}>
        <div className={styles.content} data-side={castedSide} data-widest={castedWidestContente}>
          {eyebrow && <p className={styles.eyebrow}>{eyebrow}</p>}
          <SectionRichText body={body} className={className || styles} arrowLinks />
        </div>
        <div className={styles.asset} data-side={castedSide} data-widest={castedWidestContente}>
          {video && video?.trim().length ? (
            <Video video_url={video} thumbnailUrl={image?.image} />
          ) : (
            image && <Image data={image} fluid options={{ mainImage: true }} loading="eager" noWebpFormat />
          )}
        </div>
      </div>
    </ModuleWrapper>
  );
}
