import React, { useEffect } from 'react';
import Hero from '@/components/sections/Hero';
import { sendGTMHttpError } from '@/base/helpers/gtm';
import * as HeroStyles from '@/components/sections/Hero/Hero.module.scss';
import type { NotFoundTypes } from './NotFound.types';
import '@/base/styles/main.scss';
import * as styles from './NotFound.module.scss';

export default function NotFound({ content, location }: StoryblokPage<NotFoundTypes>) {
  const { href } = location;

  useEffect(() => {
    sendGTMHttpError(href, window.locations[window.locations.length - 2] || '');
  }, []);

  return (
    <main className="container">
      <Hero {...content} image_position="center" spacing="md" className={{ ...HeroStyles, ...styles }} />
    </main>
  );
}
